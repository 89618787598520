import React from 'react'
import Content from '../components/Content';


const About = () => {
  return (
    <>
    <Content />
   
    </>
  )
}

export default About