import React from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import Title from "./Title";
import imgDetail from "../assets/Brainstorming.png";
import imgDetail2 from "../assets/img-shopify-hero-3.webp";

const GetStarted = () => {
  const CustomGridItem = styled(Grid)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  const CustomTypography = styled(Typography)({
    fontSize: "1.1rem",
    textAlign: "start",
    lineHeight: "1.5",
    color: "#515151",
    marginTop: "1.5rem",
  });

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 0 }}
      sx={{
        py: 10,
        px: 2,
      }}
    >
      <CustomGridItem item xs={12} sm={8} md={6} component="section">
        <Box
          component="article"
          sx={{
            px: 4,
          }}
        >
          <Title
            text={"Elevate Your Design with Our Creative Team"}
            textAlign={"start"}
          />
          <CustomTypography>
            Our Creative Design Team is dedicated to setting new industry
            standards by embracing the latest trends and pushing the boundaries
            of creativity. We strive to stay ahead of the curve while ensuring
            that our designs remain user-friendly and intuitive. With a passion
            for innovation, we create visually stunning and engaging experiences
            that captivate audiences and leave a lasting impression. Our team's
            expertise and commitment to excellence make us the ideal partner for
            bringing your vision to life in the most compelling and impactful
            way possible.
          </CustomTypography>
        </Box>
      </CustomGridItem>

      <Grid item xs={12} sm={4} md={6}>
        <img
          src={imgDetail}
          alt=""
          style={{
            width: "100%",
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        sx={{
          order: { xs: 4, sm: 4, md: 3 },
        }}
      >
        <img
          src={imgDetail2}
          alt=""
          style={{
            width: "100%",
          }}
        />
      </Grid>

      <CustomGridItem
        item
        xs={12}
        sm={8}
        md={6}
        sx={{
          order: { xs: 3, sm: 3, md: 4 },
        }}
      >
        <Box
          component="article"
          sx={{
            px: 4,
          }}
        >
          <Title
            text={"Delivering Cutting-Edge Applications"}
            textAlign={"start"}
          />
          <CustomTypography>
            Our mission is to provide you with feature-packed applications that
            go beyond your expectations. We strive to incorporate innovative
            features that you may not have even realized you needed. This is
            made possible by our dedicated research team, who plays a crucial
            role in identifying and implementing cutting-edge functionalities.
          </CustomTypography>
        </Box>
      </CustomGridItem>
    </Grid>
  );
};

export default GetStarted;
